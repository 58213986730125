import React, { useEffect, useRef, useState } from 'react';
import Layout from '../components/Layout';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import moment from 'moment';
import { duration } from 'moment';

const StyledRow = styled.div`
  display: flex;
`;

const StyledDateColumn = styled.div`
  padding: 3px 0px;
  min-width: 60px;
`;

const StyledNameColumn = styled.div`
  padding: 3px 0px;
  width: 400px;
`;

const StyledDateInput = styled.input`
  width: 60px;
`;

const StyledNameInput = styled.input`
  width: 100%;
`;

const StyledButtonRow = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px 0;
`;

const StyledMessage = styled.div`
  padding: 10px 0 0;
`;

const fullDateTimeFormat = 'YYYY-MM-DD HH.mm';
const timeFormat = 'HH.mm';

const getTimeDiff = (startTime, endTime) => {
  let diff = '';
  const duration = moment.duration(
    moment(endTime, fullDateTimeFormat).diff(
      moment(startTime, fullDateTimeFormat)
    )
  );
  if (duration._data.hours > 0) {
    diff += `${duration._data.hours}h`;
  }
  diff += ` ${duration._data.minutes}m`;
  return diff;
};

const jsonBinUrl = 'https://api.jsonbin.io/v3/b/63a670aedfc68e59d56fbab8';
const jsonBinKey =
  '$2b$10$9DR7dKfagPclGygPnFqMb.DqG6phby7tR4LqveDT7DRpCusgT11x6';

const Activities = props => {
  const siteTitle = get(props, 'data.site.siteMetadata.title');

  const [activities, setActivities] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [newInputStartTime, setNewInputStartTime] = useState('');
  const [newInputEndTime, setNewInputEndTime] = useState('');
  const [newInputName, setNewInputName] = useState('');
  const activityNameRef = useRef(null);

  const [message, setMessage] = useState('');

  const loadData = () => {
    setMessage('Loading data...');
    fetch(jsonBinUrl, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        'X-Master-Key': jsonBinKey,
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.record && data.record.activities) {
          setActivities(data.record.activities);
          setMessage('Data loaded successfully');
        } else {
          setMessage('Data loading failed');
        }
      });
  };

  useEffect(() => {
    loadData();

    // const activities = window.localStorage.getItem('activities');
    // if (activities) {
    //   setActivities(JSON.parse(activities));
    // }
  }, []);

  const onRefreshData = () => {
    loadData();
  };

  const onSave = () => {
    setMessage('Saving...');
    const data = {
      activities,
    };
    fetch(jsonBinUrl, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        'X-Master-Key': jsonBinKey,
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.record && data.record.activities) {
          setMessage('Successfully saved');
        } else {
          setMessage('Error occurred when saving');
        }
      })
      .catch(err => setMessage('API error when saving'));

    // window.localStorage.setItem('activities', JSON.stringify(activities));
  };

  const onEditClick = () => {
    setIsEditing(true);
  };

  const populateCurrentInputTimeValues = activityList => {
    const currentTime = moment().format(timeFormat);
    if (activityList.length > 0) {
      const formattedTime = getFormattedTimeInput(
        activityList[activityList.length - 1].endTime
      );
      setNewInputStartTime(formattedTime);
    } else {
      setNewInputStartTime(currentTime);
    }
    setNewInputEndTime(currentTime);
    activityNameRef.current.focus();
  };

  const onPopulateTime = () => {
    populateCurrentInputTimeValues(activities);
  };

  const onNewInputStartTimeChange = e => {
    setNewInputStartTime(e.target.value);
  };

  const onNewInputEndTimeChange = e => {
    setNewInputEndTime(e.target.value);
  };

  const onNewInputNameChange = e => {
    setNewInputName(e.target.value);
  };

  const resetNewInputValues = () => {
    setNewInputStartTime('');
    setNewInputEndTime('');
    setNewInputName('');
  };

  const onAddNew = () => {
    if (newInputName && newInputStartTime && newInputEndTime) {
      let isStartTimeSameToPreviousEndTime = true;
      if (activities.length > 0) {
        const formattedTime = getFormattedTimeInput(
          activities[activities.length - 1].endTime
        );
        isStartTimeSameToPreviousEndTime =
          formattedTime.trim() === newInputStartTime.trim();
      }
      const activityList = [
        ...activities,
        {
          name: newInputName.trim(),
          startTime: isStartTimeSameToPreviousEndTime
            ? activities[activities.length - 1].endTime
            : moment(newInputStartTime, timeFormat).format(fullDateTimeFormat),
          endTime: moment(newInputEndTime, timeFormat).format(
            fullDateTimeFormat
          ),
        },
      ];
      setActivities(activityList);
      setMessage('New item added');
      resetNewInputValues();
    } else {
      setMessage('Input empty fields');
    }
  };

  const onClearInput = () => {
    setNewInputName('');
    activityNameRef.current.focus();
  };

  const getFormattedTimeInput = time => {
    const formattedTime = moment(time, fullDateTimeFormat).format(timeFormat);
    return formattedTime;
  };

  return (
    <Layout location={props.location} title={siteTitle}>
      <main>
        <h1>සිහිය වඩමු</h1>
        {activities.map(activity => {
          return (
            <StyledRow>
              <StyledDateColumn>
                {getFormattedTimeInput(activity.startTime)}
              </StyledDateColumn>
              <StyledDateColumn>
                {getFormattedTimeInput(activity.endTime)}
              </StyledDateColumn>
              <StyledNameColumn>{activity.name}</StyledNameColumn>
              <StyledDateColumn>
                {getTimeDiff(activity.startTime, activity.endTime)}
              </StyledDateColumn>
            </StyledRow>
          );
        })}
        <StyledRow>
          <StyledDateColumn>
            <StyledDateInput
              value={newInputStartTime}
              onChange={onNewInputStartTimeChange}
            />
          </StyledDateColumn>
          <StyledDateColumn>
            <StyledDateInput
              value={newInputEndTime}
              onChange={onNewInputEndTimeChange}
            />
          </StyledDateColumn>
          <StyledNameColumn>
            <StyledNameInput
              ref={activityNameRef}
              list="activityNames"
              value={newInputName}
              onChange={onNewInputNameChange}
            />
            <datalist id="activityNames">
              <option value="Rest and thinking" />
              <option value="Organising and cleaning home" />
              <option value="Breakfast" />
              <option value="Bring breakfast" />
              <option value="Lunch" />
              <option value="Bring lunch" />
              <option value="Dinner" />
              <option value="Bring dinner" />
              <option value="Dhamma listening" />
              <option value="Call Pathumi" />
              <option value="Cooking" />
              <option value="Clean kitchen" />
              <option value="Wash face" />
              <option value="Make tea and organize" />
              <option value="Sleep" />
              <option value="Ent" />
              <option value="Bathing" />
              <option value="Laundry" />
              <option value="Buy groceries" />
              <option value="Sweep the floor" />
            </datalist>
          </StyledNameColumn>
        </StyledRow>

        {/* <button onClick={onEditClick}>Edit</button> */}

        <StyledMessage>{message}</StyledMessage>

        <StyledButtonRow>
          <button onClick={onAddNew}>Add New</button>
          <button onClick={onPopulateTime}>Populate Current time</button>
        </StyledButtonRow>

        <StyledButtonRow>
          <button onClick={onRefreshData}>Refresh</button>
          <button onClick={onClearInput}>Clear Input</button>
          <button onClick={onSave}>Save</button>
        </StyledButtonRow>
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ActivitiesSiteData {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Activities;
